const Carrera = props => {
    
    const state = props.state;

    return (
        <>
        <h5>Selecciona Tú Carrera</h5>
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="carrera" className="form-label">Carrera:</label>
                        <select className="form-select" id="carrera" name="carrera" value={state.carrera} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            {
                                props.carreras.map((row,index) => {
                                    return(
                                        <option key={index} value={row.codcarr}>{row.nombre_c}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.carrera}</div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="jornada" className="form-label">Jornada:</label>
                        <select className="form-select" id="jornada" name="jornada" value={state.jornada} onChange={e => props.handleOnChangeText(e)}>
                                    <option value="">Seleccione</option>
                                    {
                                        props.jornadas.map((row,index) => {
                                            return(
                                                <option key={index} value={row.jornada}>{row.descripcion}</option>
                                            );
                                        })
                                    }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.jornada}</div>
                    </div>
                </div>
            </div> 
        </div>
    </>
    );
  }
  
export default Carrera;