const Academico = props => {
    
    const state = props.state;

    return (
        <>
        <h5>Nivel Académico Actual</h5>
        <div>
            <div className="row">
                <div className="col-md-12 mt-2 mb-3">
                    <div className="mb-6">
                        <input className="form-check-input" type="checkbox" id="estudios_extranjero" name="estudios_extranjero" onChange={e => props.handleChangeChbxEstudiosExtr(e)} checked={props.CheckedEstudiosExtranjero}/>&nbsp;&nbsp;<b>Marque esta opción si cursó sus estudios en el extranjero</b>
                    </div>
                </div>
            </div>
            <div className="row" style={{display: (props.CheckedEstudiosExtranjero)? "none":""}}>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="region" className="form-label">Región:</label>
                        <select className="form-select" id="region" name="region" value={state.region} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            {
                                props.regiones.map((row,index) => {
                                    return(
                                        <option key={index} value={row.codregion}>{row.descripcion}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.region}</div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="comuna" className="form-label">Comuna:</label>
                        <select className="form-select" id="comuna" name="comuna" value={state.comuna} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            {
                                props.comuna_academico.map((row,index) => {
                                    return(
                                        <option key={index} value={row.codcom}>{row.nomcomuna}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.comuna}</div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="colegio" className="form-label">Colegio:</label>
                        <select className="form-select" id="colegio" name="colegio" value={state.colegio} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            {
                                props.colegios.map((row,index) => {
                                    return(
                                        <option key={index} value={row.codcol}>{row.nombre}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.colegio}</div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="situacion_actual" className="form-label">¿Qué año cursas?</label>
                        <select className="form-select" id="situacion_actual" name="situacion_actual" value={state.situacion_actual} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                                {
                                    props.situacion_actual.map((row,index) => {
                                        return(
                                            <option key={index} value={row.codsituacion}>{row.descripcion}</option>
                                        );
                                    })
                                }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.situacion_actual}</div>
                    </div>
                </div>
            </div> 
        </div>
    </>
    );
  }
  
export default Academico;