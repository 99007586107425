const Contacto = props => {
    
    const state = props.state;
    

    return (
      <>
        <h5>Datos de Contacto</h5>
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="telefono-celular" className="form-label">Teléfono</label>
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">+56 9</div>
                            <input maxLength={8} type="text" className="form-control" id="telefono" name="telefono" value={state.telefono} onChange={e => props.handleChangeSoloNum(e)}/>
                        </div>
                        <div className="text-danger invalid-text text-start">{props.error.telefono}</div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="mb-3">
                    <label htmlFor="confirmar-telefono-celular" className="form-label">Confirmar Teléfono:</label>
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">+56 9</div>
                            <input maxLength={8} type="text" className="form-control" id="conftelefono" name="conftelefono"  value={state.conftelefono} onChange={e => props.handleChangeSoloNum(e)}/>
                        </div>
                        <div className="text-danger invalid-text text-start">{props.error.conftelefono}</div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email:</label>
                        <input type="email" className="form-control" id="email" name="email" value={state.email} onChange={e => props.handleOnChangeText(e)}/>
                        <div className="text-danger invalid-text text-start">{props.error.email}</div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="confirmar-email" className="form-label">Confirmar Email:</label>
                        <input type="email" className="form-control" id="confemail" name="confemail" value={state.confemail} onChange={e => props.handleOnChangeText(e)}/>
                        <div className="text-danger invalid-text text-start">{props.error.confemail}</div>
                    </div>
                </div>
            </div>
        </div>     


        <h5>Contacto Emergencia</h5>
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="nombre_emergencia" className="form-label">Nombre:</label>
                        <div className="input-group mb-2 mr-sm-2">
                            <input type="text" className="form-control" id="nombre_emergencia" name="nombre_emergencia" value={state.nombre_emergencia} onChange={e => props.handleOnChangeText(e)}/>
                        </div>
                        <div className="text-danger invalid-text text-start">{props.error.nombre_emergencia}</div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="telefono_emergencia" className="form-label">Teléfono</label>
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">+56 9</div>
                            <input maxLength={8} type="text" className="form-control" id="telefono_emergencia" name="telefono_emergencia" value={state.telefono_emergencia} onChange={e => props.handleChangeSoloNum(e)}/>
                        </div>
                        <div className="text-danger invalid-text text-start">{props.error.telefono_emergencia}</div>
                    </div>
                </div>

            </div>
        </div> 



      </>
    );
  }
  
export default Contacto;