import logo from '../img/logo2023.png';
import flujo from '../img/flujo.png';
import React, {useState, useEffect} from 'react';

const Final = props => {

    const [eWhatsapp, setEventWhatsapp] = useState();

    const handleEventWhatsapp = (e)=>{
        setEventWhatsapp(e.target.value);
    }
    
    return (
        <>
            <div className="text-center mt-3 mb-3">
                <img src={logo} width="258px" height="200px"/>
            </div>
            <div className="mensaje-final ms-5 mb-2 me-5 text-center pb-2 mb-2">
                <h4>Gracias por finalizar tu pre-matrícula! <br /> Te contactaremos a la brevedad.</h4>
            </div>
            <div className="col-12 col-md-12 p-1 text-center">
                <div className="mensaje-final page-header text-left" style={{marginLeft: "13px"}}>
                    <h4>No olvides continuar con las siguientes etapas:<br/><br/></h4>
                </div>
                <center><img className="img-fluid" src={flujo} width="650px" height="277px"/></center>
            </div>
            <div className='row justify-content-center'>
                <div className="col-12 col-md-6 col-lg-4 p-3 mt-1 text-center">
                    <div className="mensaje-final mx-auto row pb-5">
                            <div className='text-center'>
                                <div className="mensaje-final">
                                    <h4>¿Tienes alguna duda?</h4>
                                </div>
                                <div>
                                    <h6>Selecciona una de las preguntas y envíanos un whatsapp</h6>
                                </div>
                                <form className='text-center'>
                                    <select className="form-select" id="whatsapp" name="whatsapp" value={eWhatsapp}  onChange={handleEventWhatsapp}>
                                        <option value="" selected>Selecciona una pregunta</option>
                                        <option value="¿Dónde se realiza el proceso de Matricula presencial?">¿Dónde se realiza el proceso de Matricula presencial?</option>
                                        <option value="¿Cuáles son los requisitos para poder acceder a la gratuidad y beneficios?">¿Cuáles son los requisitos para poder acceder a la gratuidad y beneficios?</option>
                                        <option value="¿Cuándo se inicia el proceso de matrícula 2024?">¿Cuándo se inicia el proceso de matrícula 2024?</option>
                                        <option value="¿Cuál es el costo de la matricula 2024?">¿Cuál es el costo de la matricula 2024?</option>
                                        <option value="¿Cuál es el horario de atención?">¿Cuál es el horario de atención?</option>
                                    </select>
                                </form>
                                <div className="mt-3">
                                    <a target="_blank" href={"https://wa.me/56978953861/?text=" + eWhatsapp} className="btn btn-azul">
                                        Enviar
                                    </a>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
  
export default Final;