import { toast } from 'react-toastify';

export function showToastMessageSuccess(msg) {
    const toastSuccess = toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-success-container toast-success-container-after'
    });

    return toastSuccess;
};

