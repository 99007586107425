import { formatCurrency } from '../../../_helpers';

const Personal = props => {
    
    const state = props.state;

    return (
        <>
        <h5>Información Personal</h5>
        <div>
            <div className="row">
                <div className="col-4 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="rut" className="form-label">Rut:</label>
                        <input maxLength={10} type="text" className="form-control" id="rut" name="rut" value={formatCurrency(state.rut)} onChange={e => props.handleOnChangeNum(e)}/>
                        <div className="text-danger invalid-text text-start">{props.error.rut}</div>
                    </div>
                </div>
                <div className="col-2 col-md-2">
                    <div className="mb-3">
                        <label htmlFor="dv" className="form-label">DV:</label>
                        <input type="text" className="form-control" id="dv" name="dv" value={state.dv} disabled/>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="fecha-nacimiento" className="form-label">Fecha de Nacimiento:</label>
                        <input type="date" className="form-control" id="fechanac" name="fechanac" value={state.fechanac} onChange={e => props.handleOnChangeText(e)}/>
                        <div className="text-danger invalid-text text-start">{props.error.fechanac}</div>               
                    </div>
                </div>


                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="nombres" className="form-label">Nombres:</label>
                        <input type="text" className="form-control" id="nombres" name="nombres" value={state.nombres} onChange={e => props.handleOnChangeText(e)}/>
                        <div className="text-danger invalid-text text-start">{props.error.nombres}</div>
                    </div>
                </div>

                <div className="col-6 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="primer-apellido" className="form-label">Primer Apellido:</label>
                        <input type="text" className="form-control" id="primerapellido" name="primerapellido" value={state.primerapellido} onChange={e => props.handleOnChangeText(e)}/>
                        <div className="text-danger invalid-text text-start">{props.error.primerapellido}</div>
                    </div>
                </div>

                <div className="col-6 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="segundo-apellido" className="form-label">Segundo Apellido:</label>
                        <input type="text" className="form-control" id="segundoapellido" name="segundoapellido" value={state.segundoapellido} onChange={e => props.handleOnChangeText(e)}/>
                        <div className="text-danger invalid-text text-start">{props.error.segundoapellido}</div>
                    </div>
                </div>

                <div className="col-6 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="genero" className="form-label">Género:</label>
                        <select className="form-select" id="genero" name="genero" value={state.genero} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                            <option value="O">Otro</option>
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.genero}</div>
                    </div>
                </div>

                <div className="col-6 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="nacionalidad" className="form-label">Nacionalidad:</label>
                        <select className="form-select" id="nacionalidad" name="nacionalidad" value={state.nacionalidad} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            {
                                props.nacionalidades.map((row,index) => {
                                    return(
                                        <option key={index} value={row.codnacionalidad}>{row.nacionalidad}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.nacionalidad}</div>
                    </div>
                </div>

                <div className="col-6 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="region_residencia" className="form-label">Región de Residencia:</label>
                        <select className="form-select" id="region_residencia" name="region_residencia" value={state.region_residencia} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            {
                                props.regiones.map((row,index) => {
                                    return(
                                        <option key={index} value={row.codregion}>{row.descripcion}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.region_residencia}</div>
                    </div>
                </div>


                <div className="col-6 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="comuna_residencia" className="form-label">Comuna de Residencia:</label>
                        <select className="form-select" id="comuna_residencia" name="comuna_residencia" value={state.comuna_residencia} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            {
                                props.comuna_residencia.map((row,index) => {
                                    return(
                                        <option key={index} value={row.codcom}>{row.nomcomuna}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.comuna_residencia}</div>
                    </div>
                </div>


                {
                    (state.nacionalidad > 1) &&
                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="residencia" className="form-label">Tipo de Residencia:</label>
                        <select className="form-select" id="residencia" name="residencia" value={state.residencia} onChange={e => props.handleOnChangeText(e)}>
                            <option value="">Seleccione</option>
                            <option value="definitiva">Residencia Definitiva</option>
                            <option value="temporaria">Residencia Temporaria</option>
                            <option value="refugiado">Residencia Refugiado/Asilo</option>
                            <option value="estudiante">Residencia de Estudiante</option>
                        </select>
                        <div className="text-danger invalid-text text-start">{props.error.residencia}</div>
                    </div>
                </div>
                }



            </div>
        </div>
        </>
    );
  }
  
export default Personal;