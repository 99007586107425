//import 'bootstrap/dist/css/bootstrap.min.css';

//Llamo a función home de home.js
import Home from './_components/home/home';

//Llamo a las alertas
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './_components/toast/css/toast.css';
import './App.css';

function App() {
  return (
    <>
    <div id="sec_add_modal"></div> 
    <Home />
    <ToastContainer /></>
  );
}

export default App;
